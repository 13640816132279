body {
  margin: 0;
  padding: 0;
  background-image: url("./assets/flat-lay-composition-wrapped-presents-with-copy-space.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  color: #022236;
}
html,
body {
  height: 100%;
}
body .form-control:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.box select {
  background-color: rgb(219, 159, 159);
  color: white;
  padding: 12px;
  width: 250px;
  border: none;
  font-size: 20px;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
  -webkit-appearance: button;
  appearance: button;
  outline: none;
  padding-left: 32px;
}

.box::before {
  position: absolute;
  top: 0;
  right: 0;
  width: 20%;
  height: 100%;
  text-align: center;
  font-size: 28px;
  line-height: 45px;
  color: rgba(255, 255, 255, 0.5);
  background-color: rgba(255, 255, 255, 0.1);
  pointer-events: none;
}

.box:hover::before {
  color: rgba(255, 255, 255, 0.6);
  background-color: rgba(255, 255, 255, 0.2);
}

.box select option {
  padding: 30px;
}
.left-right {
  padding: 80px;
  padding-left: 80px !important;
  padding-right: 80px !important;
}
textarea.text-area {
  height: 180px;
  resize: none;
  border: none;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.07);
}
.gift {
  height: 50px;
  width: 54px;
}
#gifty {
  opacity: 0;
  transition: all 2s ease-out;
}
.having-secret-santa-id {
  display: none;
}
/* .add-secret-santa{ */
/* display: none; */
/* } */
.add-secret-key {
  margin: 0px auto;
}
.members-dropdown {
  display: none;
}
#secretsanta-id {
  text-align: center;
}
.user-email-address {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#email-address {
  max-width: 254px;
  border: none;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.07);
}
.app-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  padding: 50px 10px;
}
.add-secret-key {
  max-width: 500px;
}
.btn {
  width: auto;
  outline: 0;
  box-shadow: none !important;
}
.mui-btn--group .btn {
  min-width: 100px;
}
.btn-info {
  background: #e80f04 !important;
  border-color: #e80f04 !important;
}
.btn-info:hover {
  background: #fff !important;
  border-color: #e33d32 !important;
  color: #e33d32 !important;
}

.mui-btn--group .btn + .btn {
  margin-left: 10px;
}

header.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  /* background-image: linear-gradient(180deg, #3e403c, rgba(255, 255, 255, 0.8)); */
  background-color: #022236;
  color: #f2f2f2;
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

header h4 {
  margin: 0;
}

.logout {
  cursor: pointer;
}
.copyright {
  position: fixed;
  bottom: 10px;
  font-size: 14px;
  color: #022236;
}
.santa {
  max-width: 100px;
}
a {
  color: #022236;
}
a:hover{
  color: #022236;
  text-decoration: underline;
}